import React from 'react';
import SeoComponent from 'components/seo';
import { useStrapiContractorAcknowledgmentsPageData } from 'hooks';
import { Hero, Breadcrumb } from "components/ui";
import { getSrc } from 'gatsby-plugin-image';
import Layout from 'components/layout';

const isBrowser = typeof window !== "undefined"

const ContractorAcknowledgments=({location})=>{
    let thisLocationPathname = ''
    if (isBrowser) {
        thisLocationPathname = window.location.pathname;
    }

    const { strapiContractorAcknowledgmentsPage } = useStrapiContractorAcknowledgmentsPageData();
    const title = strapiContractorAcknowledgmentsPage?.title;
    const seo = {
      metaTitle: strapiContractorAcknowledgmentsPage?.title,
      metaDescription: strapiContractorAcknowledgmentsPage?.description,
      metaKeywords: strapiContractorAcknowledgmentsPage?.keywords,
    }

    return (
        <Layout location={location} title={title}>
          <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
            <Hero
              img={getSrc(strapiContractorAcknowledgmentsPage?.hero_image?.background_image?.localFile)}
              color="#f5f5f5"
              isFixedHeight={false}
              >
                <Breadcrumb pathname={thisLocationPathname} customCurrentPage={strapiContractorAcknowledgmentsPage?.title}  />
                <h1 className="usa-hero__heading" aria-label={`Hero: ${title}`} name="main-content" id="main-content">{title}</h1>
            </Hero>
            <div className="padding-y-5">
              <div className="grid-container">
                <div className="grid-row grid-gap">
                  {strapiContractorAcknowledgmentsPage?.body && <div className="desktop:grid-col-7 tablet:grid-col-12"
                      dangerouslySetInnerHTML={{
                        __html: strapiContractorAcknowledgmentsPage?.body?.data?.childMarkdownRemark?.html,
                    }}
                  />}
                </div>
              </div>
            </div>
        </Layout>
    )
}

export default ContractorAcknowledgments;
